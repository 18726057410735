import React from 'react'
import Navbar from '../navbar/navbar'

function TopArea() {
  return (
    <div className="top-area" >
        <div className="header-area" >
            <Navbar />  
        </div>
        <div className="clearfix"></div>

    </div>
  );
}

export default TopArea
