import {
    faAddressCard,
    faEnvelope,
    faPhone,
    faMobilePhone
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import ContactForm from '../../components/contact_form/contact_form'
import { StMarysMap } from '../../components/map/map'
import { consentStrng } from '../../components/consent_banner/consent_banner'
import ConsentBanner from '../../components/consent_banner/consent_banner'


export default function ContactUsPage() {
    // go to to of the page at every rerender
    // TODO: CHECK FOR DEEP LINKING
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const position = { lat: -26.25062, lng: 28.056 }

    function getMapDirections() {
        window.open(
            `https://www.google.com/maps/dir/?api=1&destination=${position.lat},${position.lng}`,
            '_blank'
        )
    }

    return (
        <div className="contactus">
            {sessionStorage.getItem('Consent') ? '' : <ConsentBanner />}

            <h2>Contact us</h2>
            <h6>Any questions or remarks ? Just write us a message.</h6>

            <div className="row contactus-wrapper">
                <div className="col-md-4 contactus-info">
                    <h5> contact information</h5>
                    <p>
                        Feel free to contact us using any of the details
                        provided below.
                    </p>

                    <div className="contactus-item-wrapper">
                        <div className="col-md-12 contactus-item">
                            <span>
                                <FontAwesomeIcon
                                    className="contactus-icon"
                                    icon={faPhone}
                                />
                            </span>

                            <span> 011 435 3820</span>
                        </div>

                        <div className="col-md-12 contactus-item">
                            <span>
                                <FontAwesomeIcon
                                    className="contactus-icon"
                                    icon={faMobilePhone}
                                />
                            </span>

                            <span>083 267 3515</span>
                        </div>

                        <div className="col-md-12 contactus-item">
                            <span>
                                <FontAwesomeIcon
                                    className="contactus-icon"
                                    icon={faEnvelope}
                                />
                            </span>

                            <span> publicrelations@smch.co.za </span>
                        </div>

                        <div className="col-md-12 contactus-item">
                            <span>
                                <FontAwesomeIcon
                                    className="contactus-icon"
                                    icon={faAddressCard}
                                />
                            </span>

                            <span>
                                {' '}
                                42 Zinnia Street, Rosetenville Johannesburg 2190
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 contactus-form">
                    <ContactForm />
                </div>
            </div>

            <div className="contactus-map-wrapper">
                <StMarysMap position={position} />
                <button
                    className="getdirections-btn"
                    onClick={() => getMapDirections()}
                >
                    {' '}
                    Get Directions
                </button>
            </div>

            {/* <div className="contactus-button-wrapper"></div> */}
        </div>
    )
}
