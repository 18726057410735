// TODO: Replace the following with your app's Firebase project configuration
// Import the functions you need from the SDKs you need
// import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// TODO: Add config to env file.
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDrKcgY3n26y81LL37OVLymjorT5kmsENA',
    authDomain: 'st-marys-app-c570e.firebaseapp.com',
    projectId: 'st-marys-app-c570e',
    storageBucket: 'st-marys-app-c570e.appspot.com',
    messagingSenderId: '289640831143',
    appId: '1:289640831143:web:9434beafae24ffe655a382',
    measurementId: 'G-QWC2TT8V7G',
}

// Initialize Firebase, anylitics and cloud messaging
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
const messaging = getMessaging(app)

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
        vapidKey:
            'BIKXtGjU-25V_XyExNudFnFuE_5CLcLsC0TLtpb9x_tyrG7Sy13dypaGLfVsiWAp3ukt4qFfl5D6OnOYnpEj1O8',
    })
        .then((currentToken) => {
            if (currentToken) {
                //console.log('current token for client: ', currentToken)
                setTokenFound(true)
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log(
                    'No registration token available. Request permission to generate one.'
                )
                setTokenFound(false)
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // catch error while creating client token
        })
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
