class AuthManager {
    #isAuthenticated = false
    #token = null

    geToken() {
        return this.#token
    }

    geIsAuthenticated() {
        return this.#isAuthenticated
    }

    /**
     * used to update the value of the user's token
     *
     * @param {String} token
     * @memberof AuthManager
     */
    setToken(token) {
        this.#token = token
    }

    /**
     * setIsAuthenticated will update the current authentication
     * status for a given user.
     *
     * @param {boolean} values
     * @memberof AuthManager
     */
    setIsAuthenticated(value) {
        this.#isAuthenticated = value
    }
}

// export authManager singleton
export default new AuthManager()
