export class ContactForm_M {
    static name = 'name'
    static lastName = 'lastName'
    static email = 'email'
    static mobileNumber = 'mobileNumber'
    static message = 'message'

    name = ''
    lastName = ''
    email = ''
    mobileNumber = ''
    message = ''

    /**
     *
     *
     * @static
     * @return {Object}
     * @memberof ContactForm_M
     */
    static FormState = {
        name: this.name,
        lastName: this.name,
        email: this.name,
        mobileNumber: this.name,
        message: this.name,
    }

    /**
     * a method used to find out id a used has filled in the
     * required form fields.
     *
     * @return {Boolean}
     * @memberof ContactFormModel
     */
    isFilled() {
        return !!this.name && !!this.lastName && this.email && this.mobileNumber
    }

    /**
     * a method used to update the value of the name
     *
     * @param {String} name
     * @memberof ContactForm_M
     */
    setName(name) {
        this.name = name
    }

    /**
     * a method used to update the value of the lastName
     *
     * @param {String} lastName
     * @memberof ContactForm_M
     */
    setLastName(lastName) {
        this.lastName = lastName
    }

    /**
     * a method used to update the value of the mobileNumber
     *
     * @param {String} mobileNumber
     * @memberof ContactForm_M
     */
    setMobileNumber(mobileNumber) {
        this.mobileNumber = mobileNumber
    }

    /**
     * a method used to update the value of the email
     *
     * @param {String} email
     * @memberof ContactForm_M
     */
    setEmail(email) {
        this.email = email
    }

    /**
     * a method used to update the value of the forms message
     *
     * @param {String} name
     * @memberof ContactForm_M
     */
    setMessage(message) {
        this.message = message
    }
}
