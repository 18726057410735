import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const blurBodyWrapper = (blurAmount) => {
    document.querySelector('#root').style.filter = `blur(${blurAmount}rem)`
}

/**
 * this function calls the toast function with alerts a success message
 */
export const successNotification = (message, milliseconds) =>
    toast(message, {
        type: 'success',
        autoClose: milliseconds || 5000,
        hideProgressBar: false,
    })

/**
 * this function calls the toast function with alerts an error message
 **/
export const errorNotification = (message, milliseconds) =>
    toast(message, {
        type: 'error',
        autoClose: milliseconds || 5000,
        hideProgressBar: false,
    })

/**
 * this function calls the toast function with alerts a warning message
 */
export const warningNotification = (message, milliseconds) =>
    toast(message, {
        type: 'warning',
        autoClose: milliseconds || 5000,
        hideProgressBar: false,
    })

export const isValidEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
