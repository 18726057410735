import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMessage,
    faEnvelope,
    faPhone,
    faSignature,
} from '@fortawesome/free-solid-svg-icons'

import React, { useState, useEffect } from 'react'
import { logInfo, logWarning } from '../../utilities/log'
import { ContactForm_M } from '../../models/contact_form'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ToastContainer } from 'react-toastify'
import { successNotification, errorNotification } from '../../utilities/common'

/**
 *
 *
 * @export
 * @return {*}
 */
export default function ContactForm({ message, closeModal, type }) {
    // form data
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm()

    let [formState, setFormState] = useState({
        name: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        message: '',
    })
    const [formLoading, setFormLoading] = useState(false)

    useEffect(() => {
        if (message) {
            setFormState((prevState) => ({
                ...prevState,
                message: message,
            }))
        }
        return () => {}
    }, [])

    // functions

    /**
     * a method used to update form state
     *
     * @param {Event} event
     */
    function onInputChange(key, event) {
        if (event.target) {
            const stateCopy = { ...formState }
            logInfo('[setFormState] stateCopy : ', stateCopy)

            logInfo(
                `[contact_form] -> onInputChange key: ${key}  value: ${event.target.value}`
            )

            setFormState((prevState) => ({
                ...prevState,
                [key]: event.target.value,
            }))
        } else {
            logWarning(`no target found for input ${key} `)
        }
    }

    function submitForm(params) {
        setTimeout(() => {
            closeModal()
        }, 2000)
    }

    /**
     * this function is scrolls to the top of the page in order for the user to see the
     * result of submiting the form
     */
    const scrolltotop = () => {
        window.scrollTo({
            top: 20,
            behavior: 'smooth',
        })
    }

    /**
     * - this function captures the input data from the contact input form,
     * -
     * -
     */
    const saveUsersDetails = handleSubmit(async (data) => {
        setFormLoading(true)
        // TODO: checks

        await fetch(`${process.env.REACT_APP_API_URL}/volunteer`, {
            method: 'POST',
            body: JSON.stringify({
                email: data.email,
                first_name: data.name,
                last_name: data.lastName,
                phone_number: data.mobileNumber,
                message: data.message,
            }),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                console.log(response)
                // TODO: this error check is not working
                if (response.error) {
                    errorNotification('Oops! failed to save your application')
                    return
                }
                if (response.message) {
                    successNotification(response?.message ?? '')
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    setFormState({
                        name: '',
                        lastName: '',
                        email: '',
                        mobileNumber: '',
                        message: '',
                    })
                }
            })
            .catch((error) => {
                if (error.message) {
                    errorNotification(error.message)
                }
                errorNotification('Oops! failed to save your application')
            })
            .finally(() => {
                setFormLoading(false)
            })
    })

    return (
        <div
            className={
                'contact-form' + type === 'contact-form-modal'
                    ? 'contact-form-modal'
                    : 'contact-form-page'
            }
        >
            <form
                className={
                    'contact-form-container' + type === 'contact-form-modal'
                        ? 'contact-form-modal'
                        : 'contactform-pageContainer'
                }
            >
                <div className="contact-form">
                    <form className="contact-form-container">
                        <div className="row contact-form-row">
                            {/* name */}
                            <div className="col-md-6">
                                <div className="contact-form-material-textfield">
                                    <input
                                        value={formState.name}
                                        {...register('name', {
                                            required: 'enter a valid name',
                                        })}
                                        placeholder=""
                                        onChange={(e) =>
                                            onInputChange('name', e)
                                        }
                                    />
                                    <label>name</label>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faSignature} />
                                    </span>
                                </div>
                            </div>

                            {/* surname */}
                            <div className="col-md-6">
                                <div className="contact-form-material-textfield">
                                    <input
                                        value={formState.lastName}
                                        placeholder=""
                                        {...register('lastName', {
                                            required: 'enter a valid lastName',
                                        })}
                                        onChange={(e) =>
                                            onInputChange('lastName', e)
                                        }
                                    />
                                    <label>last name</label>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faSignature} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row contact-form-row">
                            {/* email */}
                            <div className="col-md-12">
                                <div className="contact-form-material-textfield">
                                    <input
                                        value={formState.email}
                                        placeholder=" "
                                        {...register('email', {
                                            required: 'enter a valid email',
                                        })}
                                        onChange={(e) =>
                                            onInputChange('email', e)
                                        }
                                    />
                                    <label>email address</label>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row contact-form-row">
                            {/* mobile number */}
                            <div className="col-md-12">
                                <div className="contact-form-material-textfield">
                                    <input
                                        value={formState.mobileNumber}
                                        // type='number'
                                        placeholder=""
                                        {...register('mobileNumber', {
                                            required:
                                                'enter a valid mobileNumber',
                                        })}
                                        onChange={(e) =>
                                            onInputChange('mobileNumber', e)
                                        }
                                    />
                                    <label>mobile number</label>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="row contact-form-row">
                            <div className="col-md-12 ">
                                <div className="contact-form-material-textfield contact-form-textarea">
                                    <textarea
                                        value={formState.message}
                                        rows="6"
                                        placeholder=" "
                                        {...register('message', {
                                            required: 'enter a valid message',
                                        })}
                                        onChange={(e) =>
                                            onInputChange('message', e)
                                        }
                                    ></textarea>
                                    <label>message</label>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faMessage} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row contact-form-row">
                            <div className="col-md-12">
                                <button
                                    className="contact-form-button"
                                    onClick={(e) => saveUsersDetails(e)}
                                >
                                    {formLoading ? 'Processing' : 'Submit'}
                                    <span
                                        className={
                                            'spinner ' +
                                            (formLoading
                                                ? 'spinner-active'
                                                : '')
                                        }
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    )
}
