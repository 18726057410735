import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logos/stmarys-logo-removebg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { paths } from '../../routes/routes'

export default function Navbar() {
    /**
     * a method used to close the navbar when a
     * mobile navitem is clicked
     *
     */
    const closeNavBar = () => {
        if (window.innerWidth <= 800) {
            const nav = document.querySelector(
                '.navbar-collapse.menu-ui-design.collapse'
            )
            if (nav) nav.classList.remove('in')
        }
    }

    return (
        <nav
            className="navbar navbar-default bootsnav  navbar-sticky navbar-scrollspy"
            data-minus-value-desktop="70"
            data-minus-value-mobile="55"
            data-speed="1000"
        >
            <div className="container">
                <div className="attr-nav">
                    <ul>
                        <li className="dropdown">
                            {/* <a href="#" className="dropdown-toggle" data-toggle="dropdown" >
                                <span className="lnr lnr-cart"></span>
                                <span className="badge badge-bg-1">2</span>
                            </a> */}
                            <Link to={paths.News}>
                                {/* <span className="lnr lnr-cart"></span>
                                <span className="badge badge-bg-1">2</span> */}
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle"
                        data-toggle="collapse"
                        data-target="#navbar-menu"
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>

                    <a className="navbar-brand" href="">
                        <span className="logo-container">
                            <img src={logo} alt="logo" />
                        </span>{' '}
                    </a>
                </div>

                <div
                    className="collapse navbar-collapse menu-ui-design"
                    id="navbar-menu"
                >
                    <ul
                        className="nav navbar-nav navbar-center"
                        data-in="fadeInDown"
                        data-out="fadeOutUp"
                    >
                        <li
                            className="scroll active"
                            onClick={() => closeNavBar()}
                        >
                            <Link className="scroll active" to={paths.Home}>
                                Home
                            </Link>
                        </li>
                        <li className="scroll" onClick={() => closeNavBar()}>
                            <Link className="scroll active" to={paths.News}>
                                News
                            </Link>
                        </li>
                        <li className="scroll" onClick={() => closeNavBar()}>
                            <Link className="scroll active" to={paths.AboutUs}>
                                About Us
                            </Link>
                        </li>
                        <li className="scroll" onClick={() => closeNavBar()}>
                            <Link
                                className="scroll active"
                                to={paths.ContactUs}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
