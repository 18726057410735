/**
 * logWarning will send the general message to all relevant anylytics
 * services and then print the message in the standard output (console)
 * of the machine in question.
 *
 * @param {any} message
 */
export const logInfo = (message, ...args) => {
    // TODO: post client logs

    // post to anylytic ie Sentry
    // TODO : only log in development mode.
    if (true) {
        console.log('[INFO] ', message, ...args)
    }
}

/**
 * logWarning will send the warning to all relevant anylytics
 * services and then print the message in the standard output (console)
 * of the machine in question.
 *
 * @param {any} message
 */
export const logWarning = (message) => {
    // TODO: post client logs

    // post warning to anylytic ie Sentry
    // TODO : only log in development mode.
    if (true) {
        console.log('[WARNING] ', message)
    }
}

/**
 * logWarning will send the error to all relevant anylytics
 * services and then print the message in the standard output (console)
 * of the machine in question.
 *
 * @param {any} message
 */
export const logError = (message) => {
    // TODO: post client logs

    // post warning to anylytic ie Sentry
    console.log('[Error] ', message)
}

/**
 * logTable will send the warning to all relevant anylytics
 * services and then print the message in the standard output (console)
 * of the machine in question.
 *
 * handy method for displating lists of data in table form
 *
 * @param {Array} message
 */
export const logTable = (data) => {
    // TODO: post client logs

    // post warning to anylytic ie Sentry

    // TODO : only log in development mode.
    if (true) {
        console.table('[WARNING] ', data)
    }
}
