import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useState } from 'react'

export let consentStrng = sessionStorage.getItem('Consent')

function ConsentBanner() {
    const [consentBanner, hidebanner] = useState('consentBanner')

    /**
     *  this function saves the consent string in session storage if the user clicks the accept button
     *
     */
    const answerConsent = (e, consent) => {
        e.preventDefault()

        if (consent === 'accepted') {
            sessionStorage.setItem('Consent', JSON.stringify(consent))
            hidebanner('hidebanner')
        } else {
            console.log('read more')
            window.open('https://popia.co.za/', 'blank')
        }
    }

    return (
        <div
            className={
                'consent-wrapper ' +
                (consentBanner === 'hidebanner' ? consentBanner : '')
            }
        >
            <div className={consentBanner}>
                <div className="consentHeading">
                    <h1>We value your privacy</h1>
                </div>

                <div className="consentInfo">
                    <p>
                        By using this website, you consent to the collection and
                        processing of your personal data as described in our
                        Privacy Policy. We value your privacy and are committed
                        to protecting your personal information in accordance
                        with applicable laws and regulations.
                    </p>
                </div>

                <div className="consentButtons">
                    <button
                        className="button"
                        id="readMore"
                        onClick={(e) => answerConsent(e, 'read more')}
                    >
                        Read More
                    </button>
                    <button
                        className="button"
                        id="acceptBtn"
                        onClick={(e) => answerConsent(e, 'accepted')}
                    >
                        Accept
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConsentBanner
