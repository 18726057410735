import axios from 'axios'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import {
    faFacebookF,
    faLinkedin,
    faInstagram,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { paths } from '../../routes/routes'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { successNotification, errorNotification } from '../../utilities/common'

export default function Footer() {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm()

    /**
     * this function calls add subscriber which will submit the subscriber forms data and capture it
     */
    const handlePostSubscriber = () => {
        addSubscriber()
    }

    /**
     * - this function captures the input data from the subscribe input,
     * - sends a post request with the email from the input to send that information to the db
     * - to eventualy be saved as a subscriber of the newsletter
     */
    const addSubscriber = handleSubmit(async (data) => {
        await fetch(`${process.env.REACT_APP_API_URL}/subscriber`, {
            method: 'POST',
            body: JSON.stringify({ email: data.email }),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                console.log(response)
                if (response.error) {
                    errorNotification('failed to save your email')
                    return
                }
                successNotification(response.message)
            })
            .catch((error) => {
                console.log(error)
                errorNotification(' failed to save your email')
            })
    })

    return (
        <>
            <section id="newsletter" className="newsletter">
                <div className="container footer-wrapper">
                    <div className="hm-footer-details">
                        <div className="row">
                            <div className=" col-md-3 col-sm-6 col-xs-12">
                                <div className="hm-footer-widget">
                                    <div className="hm-foot-title">
                                        <h4>Home</h4>
                                    </div>
                                    <div className="hm-foot-menu">
                                        <ul>
                                            <li>
                                                <Link to={paths.ContactUs}>
                                                    contact us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={paths.News}>
                                                    news
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={paths.sponserAchild}>
                                                    sponsor a child
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-3 col-sm-6 col-xs-12">
                                <div className="hm-footer-widget">
                                    <div className="hm-foot-title">
                                        <h4>About us</h4>
                                    </div>
                                    <div className="hm-foot-menu">
                                        <ul>
                                            <li>
                                                <Link to={paths.AboutUs}>
                                                    about us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={paths.AboutUs}>
                                                    the board
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={paths.AboutUs}>
                                                    staff
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-3 col-sm-6 col-xs-12">
                                <div className="hm-footer-widget">
                                    <div className="hm-foot-title">
                                        <h4>news</h4>
                                    </div>
                                    <div className="hm-foot-menu">
                                        <ul>
                                            <li>
                                                <Link to={paths.wishlist}>
                                                    wishlist
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={paths.community}>
                                                    Community
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-3 col-sm-6  col-xs-12">
                                <div className="hm-footer-widget">
                                    <div className="hm-foot-title">
                                        <h4>newsletter</h4>
                                    </div>
                                    <div className="hm-foot-para">
                                        <p>
                                            Subscribe to get latest news,update
                                            and information.
                                        </p>
                                    </div>
                                    <div className="hm-foot-email">
                                        <form
                                            onSubmit={addSubscriber}
                                            className="foot-email-box"
                                        >
                                            <input
                                                {...register('email', {
                                                    required:
                                                        'enter a valid email',
                                                })}
                                                className="form-control"
                                                placeholder="Enter Email Here...."
                                            />
                                        </form>
                                        <div className="foot-email-subscribe">
                                            <span
                                                onClick={() =>
                                                    handlePostSubscriber()
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faLocationArrow}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer id="footer" className="footer">
                <div className="container">
                    <div className="hm-footer-copyright text-center">
                        <div className="footer-social">
                            <span className="footer_social_icon">
                                <a
                                    href="https://www.facebook.com/stmaryschildrenshomeza"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon
                                        icon={faFacebookF}
                                        style={{ ':hover': 'red' }}
                                    />
                                </a>
                            </span>

                            <span
                                style={{ display: 'inline-block', width: 15 }}
                            ></span>
                            <a
                                href="https://www.instagram.com/stmarys.childrenshome/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="footer_social_icon"
                                />
                            </a>
                            <span
                                style={{ display: 'inline-block', width: 15 }}
                            ></span>
                            <FontAwesomeIcon
                                icon={faTwitter}
                                className="footer_social_icon"
                            />
                        </div>
                        <p>
                            &copy;copyright. ST Marys {new Date().getFullYear()}
                        </p>
                    </div>
                </div>

                <div id="scroll-Top">
                    <div className="return-to-top">
                        <i
                            id="scroll-top"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Back to Top"
                            aria-hidden="true"
                        >
                            <FontAwesomeIcon icon={faAngleUp} />
                        </i>
                    </div>
                </div>
            </footer>
        </>
    )
}
