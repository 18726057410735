import './sass/main.scss'
import { useEffect, useState } from 'react'
import { fetchToken, onMessageListener } from './firebase'
import { Route, Routes } from 'react-router'
import { routes } from './routes/routes'
import ProtectedRoute from './routes/protected_routes'
import Footer from './components/footer/footer'
import Navbar from './components/navbar/navbar'
import TopArea from './components/top_area/top_area'
import { ToastContainer, toast } from 'react-toastify'

// import './utilities/loadJquery'

function App() {
    const notify = () =>
        toast('success', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        })

    const [show, setShow] = useState(false)
    const [notification, setNotification] = useState({ title: '', body: '' })
    const [isTokenFound, setTokenFound] = useState(false)
    //fetchToken(setTokenFound)

    onMessageListener()
        .then((payload) => {
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            })
            setShow(true)
            console.log(payload)
        })
        .catch((err) => console.log('failed: ', err))

    const onShowNotificationClicked = () => {
        setNotification({
            title: 'Notification',
            body: 'This is a test notification',
        })
        setShow(true)
    }

    useEffect(() => {}, [])

    return (
        <div>
            <TopArea />

            <Routes>
                {routes.map((route, index) => {
                    if (route.isProtected) {
                        return (
                            <ProtectedRoute
                                key={index}
                                component={route.component}
                            />
                        )
                    } else {
                        const Page = route.component
                        return (
                            <Route
                                // exact={route.exact}
                                key={index}
                                path={route.path}
                                element={<Page />}
                            />
                        )
                    }
                })}
            </Routes>
            <Footer alert={notify} />
            <ToastContainer
                className="main-toast-styles"
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            ></ToastContainer>
        </div>
    )
}

export default App
