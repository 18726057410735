import React, { Component } from 'react'

/**
 * async  is a highre order Component used to lazyload
 * components if and when they are needed.
 * @param {Function} importComponent
 * @returns
 */
const async = (importComponent) => {
    return class extends Component {
        state = {
            component: null,
        }

        // the component will only be loaded if the content
        // requires to be mounted onto the DOM , else we render null
        componentDidMount() {
            importComponent().then((cmp) => {
                this.setState({ component: cmp.default })
            })
        }

        render() {
            const C = this.state.component
            return C ? <C {...this.props} /> : null
        }
    }
}

export default async
