import React from 'react'
import authManager from '../utilities/auth_manager'
import { Navigate, Route, useLocation, useNavigate,  } from 'react-router-dom'

export default function ProtectedRoute({ component: Component, ...rest }) {
    const location = useLocation();
    const navigate = useNavigate()
    // TODO: rather navigate to the page the user was on , instead
    // of going back to /
    // make sure that the user is authenticated before rendering this page in question
    return (
        <Route
            // path=''
            render={(props) => {
                if (authManager.geIsAuthenticated()) {
                    return <Component {...props} />
                } else {
                    return <Navigate to={'/'} state={{ from: location.path }} />
                }
            }}
        />
    )
}
