import React, { useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import { useRef } from 'react'
import { useEffect } from 'react'

// key: 'AIzaSyCWHOe5v5kRHd_Rs1VUThFzIPuEbTcsgrs'
export function StMarysMap(props) {
    const { position } = props

    // const ref = useRef()
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCWHOe5v5kRHd_Rs1VUThFzIPuEbTcsgrs',
    })

    useEffect(() => {
        console.log('maps rendering')
    }, [])

    if (!isLoaded) return <div>loading..</div>

    return (
        <GoogleMap
            zoom={15}
            center={position}
            mapContainerClassName="mapContainer"
        >
            <Marker position={position} />
        </GoogleMap>
    )
}
